import * as Sentry from '@sentry/vue';

function initializeSentry({ router = null } = {}) {
  return {
    install(app) {
      const sentryConfig = window.sentryConfig;
      if (sentryConfig) {
        const integrations = [Sentry.replayIntegration()];

        if (router) {
          integrations.push(Sentry.browserTracingIntegration({ router }));
        }

        Sentry.init({
          debug: sentryConfig.debugMode,
          app,
          dsn: sentryConfig.api_url.dsn,
          integrations,
          tracesSampleRate: sentryConfig.debugMode ? 1.0 : 0.5,
          tracePropagationTargets: sentryConfig.api_url.whitelistUrls,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0
        });
      }
    }
  };
}
export default initializeSentry;
