import { globalAxios } from '@yuzulabs/lib-infra';
import {
  PlatformUrls, platformUrlResolver, studyAppGalleryGallerySearchFeedFeaturedOpt, studyAppGalleryGallerySearchFeedRecentOpt
} from '@yuzulabs/lib-general';
import {
  AggregatedGscModel,
  AggregatedLanguageModel,
  OrgServiceConfigModel,
  SearchFilterModel,
  StudyCardModel,
  StudySuggestionModel
} from '../models';

export class StudyService {
  constructor(baseUrl, orgServiceConfig = null) {
    this.galleryCode = baseUrl === '/' ? '' : baseUrl.slice(1, -1);
    this.studiesEndpoint = `${window.location.origin + baseUrl}studies`;
    this.orgServiceConfig = orgServiceConfig || new OrgServiceConfigModel({});
  }

  // Get a list of aggregated search categories (used on Home page)
  async getHomePageSearchCategories() {
    const response = await globalAxios.get(`${platformUrlResolver(PlatformUrls.gallerySectionList, [this.galleryCode])}`);
    if (!response || !response.data) {
      return [];
    }
    return (response.data.search_categories || []).map(x => AggregatedGscModel.createFromBackendData(x));
  }

  // Get a list of featured studies used on the home page
  async getFeaturedStudies(filter) {
    const response = await globalAxios.get(`${platformUrlResolver(PlatformUrls.galleryStudySearch, [this.galleryCode])}`, {
      params: {
        ...filter.toApiPayload(),
        most_recent: true,
        search_feed: studyAppGalleryGallerySearchFeedFeaturedOpt,
        skip_similar_studies: true,
        extra_aggregations: []
      }
    });

    if (!response || !response.data) {
      return [];
    }
    const defaultMapper = x => StudyCardModel.createFromBackendData(x);
    const studyCardMapper = this.orgServiceConfig.customStudyCardMapper || defaultMapper;
    return (response.data.studies || []).map(studyCardMapper);
  }

  // Get a list of recent studies used on the home page
  async getRecentStudies(filter) {
    const response = await globalAxios.get(`${platformUrlResolver(PlatformUrls.galleryStudySearch, [this.galleryCode])}`, {
      params: {
        ...filter.toApiPayload(),
        most_recent: true,
        search_feed: studyAppGalleryGallerySearchFeedRecentOpt,
        skip_similar_studies: true,
        extra_aggregations: []
      }
    });

    if (!response || !response.data) {
      return [];
    }
    const defaultMapper = x => StudyCardModel.createFromBackendData(x);
    const studyCardMapper = this.orgServiceConfig.customStudyCardMapper || defaultMapper;
    return (response.data.studies || []).map(studyCardMapper);
  }

  // Get a list of studies and aggregated data (search categories, languages,..)
  async getStudiesAndAggregations(filter, extraAggregations) {
    const extraFilterData = this.orgServiceConfig.getStudiesAndAggregationsFilterExtraData || {};
    const params = {
      ...filter.toApiPayload(),
      extra_aggregations: extraAggregations,
      ...extraFilterData
    };

    const response = await globalAxios.get(`${platformUrlResolver(PlatformUrls.galleryStudySearch, [this.galleryCode])}`, {
      params
    });

    if (!response || !response.data) {
      return { studies: [], searchCategories: [] };
    }

    const defaultMapper = x => StudyCardModel.createFromBackendData(x);
    const studyCardMapper = this.orgServiceConfig.customStudyCardMapper || defaultMapper;
    const mappedStudies = (response.data.studies || []).map(studyCardMapper);
    const mappedSearchCategories = (response.data.search_categories || []).map(x => AggregatedGscModel.createFromBackendData(x));
    const mappedLanguages = (response.data.languages || []).map(x => new AggregatedLanguageModel(x));
    const mappedFilters = (response.data.enriched_search_filters || []).map(x => SearchFilterModel.mapFromBackend(x));
    return {
      studies: mappedStudies,
      searchCategories: mappedSearchCategories,
      languages: mappedLanguages,
      enrichedFilters: mappedFilters,
      total: response.data.total
    };
  }

  // Get study url after clicking on a suggestion
  // Either a studyId is used, or in the case of vet registries, a vct code
  async getRedirectStudyUrl({ studyId = null, vctCode = null }) {
    const response = await globalAxios.get(`${platformUrlResolver(PlatformUrls.gallerySuggestRedirect, [this.galleryCode])}`, {
      params: {
        study_id: studyId,
        vct_code: vctCode
      }
    });

    return response.data.redirect;
  }

  // Get a list of study suggestions
  async getStudySuggestions(suggestionQuery) {
    const response = await globalAxios.get(`${platformUrlResolver(PlatformUrls.galleryElasticSuggest, [this.galleryCode])}`, {
      params: {
        suggest_input: suggestionQuery
      }
    });

    if (!response || !response.data) {
      return [];
    }
    const defaultMapper = x => StudySuggestionModel.createFromBackendData(x);
    const suggestionMapper = this.orgServiceConfig.customSuggestionMapper || defaultMapper;
    return (response.data.all_suggestions || []).map(suggestionMapper);
  }

  // Subscribe a user
  async subscribeToStudies(subscription) {
    const response = await globalAxios.post(
      `${platformUrlResolver(PlatformUrls.gallerySubscribe, [this.galleryCode])}`,
      subscription.mapForBackend()
    );
    return response && response.status === 201; // Should get CREATED OK back
  }
}
