export const cssVariables = {
  // General
  spAccentPrimary: 'spAccentPrimary',
  spAccentSecondary: 'spAccentSecondary',

  // Primary button
  spBtnPrimaryColor: 'spBtnPrimaryColor',
  spBtnPrimaryBgColor: 'spBtnPrimaryBgColor',
  spBtnPrimaryBgColorHover: 'spBtnPrimaryBgColorHover',
  spBtnPrimaryColorDisabled: 'spBtnPrimaryColorDisabled',
  spBtnPrimaryBgColorDisabled: 'spBtnPrimaryBgColorDisabled',

  // Secondary button
  spBtnSecondaryColor: 'spBtnSecondaryColor',
  spBtnSecondaryBorderColor: 'spBtnSecondaryBorderColor',
  spBtnSecondaryBgColor: 'spBtnSecondaryBgColor',
  spBtnSecondaryBgColorHover: 'spBtnSecondaryBgColorHover',
  spBtnSecondaryColorHover: 'spBtnSecondaryColorHover',
  spBtnSecondaryColorDisabled: 'spBtnSecondaryColorDisabled',
  spBtnSecondaryBgColorDisabled: 'spBtnSecondaryBgColorDisabled',
  spBtnSecondaryBorderColorDisabled: 'spBtnSecondaryBorderColorDisabled',

  // Secondary button with icon
  spBtnSecondaryWithIconColor: 'spBtnSecondaryWithIconColor',
  spBtnSecondaryWithIconBorderColor: 'spBtnSecondaryWithIconBorderColor',
  spBtnSecondaryWithIconBgColor: 'spBtnSecondaryWithIconBgColor',
  spBtnSecondaryWithIconBgColorHover: 'spBtnSecondaryWithIconBgColorHover',
  spBtnSecondaryWithIconColorHover: 'spBtnSecondaryWithIconColorHover',
  spBtnSecondaryWithIconBorderColorHover: 'spBtnSecondaryWithIconBorderColorHover',
  spBtnSecondaryWithIconColorDisabled: 'spBtnSecondaryWithIconColorDisabled',
  spBtnSecondaryWithIconBgColorDisabled: 'spBtnSecondaryWithIconBgColorDisabled',
  spBtnSecondaryWithIconBorderColorDisabled: 'spBtnSecondaryWithIconBorderColorDisabled',

  // Navigation
  spNavbarLink: 'spNavbarLink',
  spNavbarLinkHover: 'spNavbarLinkHover',
  spNavbarLinkBottom: 'spNavbarLinkBottom'
};

export const settingsSections = {
  general: {
    description: 'General',
    variables: [
      cssVariables.spAccentPrimary,
      cssVariables.spAccentSecondary
    ]
  },
  primaryButton: {
    description: 'Primary Button',
    variables: [
      cssVariables.spBtnPrimaryColor,
      cssVariables.spBtnPrimaryBgColor,
      cssVariables.spBtnPrimaryBgColorHover,
      cssVariables.spBtnPrimaryColorDisabled,
      cssVariables.spBtnPrimaryBgColorDisabled
    ]
  },
  secondaryButton: {
    description: 'Secondary Button',
    variables: [
      cssVariables.spBtnSecondaryColor,
      cssVariables.spBtnSecondaryBorderColor,
      cssVariables.spBtnSecondaryBgColor,
      cssVariables.spBtnSecondaryBgColorHover,
      cssVariables.spBtnSecondaryColorHover,
      cssVariables.spBtnSecondaryColorDisabled,
      cssVariables.spBtnSecondaryBgColorDisabled,
      cssVariables.spBtnSecondaryBorderColorDisabled
    ]
  },
  secondaryButtonWithIcon: {
    description: 'Secondary Button (with icon)',
    variables: [
      cssVariables.spBtnSecondaryWithIconColor,
      cssVariables.spBtnSecondaryWithIconBorderColor,
      cssVariables.spBtnSecondaryWithIconBgColor,
      cssVariables.spBtnSecondaryWithIconBgColorHover,
      cssVariables.spBtnSecondaryWithIconColorHover,
      cssVariables.spBtnSecondaryWithIconBorderColorHover,
      cssVariables.spBtnSecondaryWithIconColorDisabled,
      cssVariables.spBtnSecondaryWithIconBgColorDisabled,
      cssVariables.spBtnSecondaryWithIconBorderColorDisabled
    ]
  },
  navigation: {
    description: 'Navigation',
    variables: [
      cssVariables.spNavbarLink,
      cssVariables.spNavbarLinkHover,
      cssVariables.spNavbarLinkBottom
    ]
  }
};

export const cssVariableMappings = {
  // Primary button
  [cssVariables.spAccentPrimary]: { varName: '--accent-primary', description: 'Accent color (primary)' },
  [cssVariables.spAccentSecondary]: { varName: '--accent-secondary', description: 'Accent color (secondary)' },

  // Primary button
  [cssVariables.spBtnPrimaryColor]: { varName: '--sp-btn-primary-color', description: 'Text color' },
  [cssVariables.spBtnPrimaryBgColor]: { varName: '--sp-btn-primary-bg-color', description: 'Background color' },
  [cssVariables.spBtnPrimaryBgColorHover]: { varName: '--sp-btn-primary-bg-color-hover', description: 'Background color (on hover)' },
  [cssVariables.spBtnPrimaryColorDisabled]: { varName: '--sp-btn-primary-color-disabled', description: 'Text color (when disabled)' },
  [cssVariables.spBtnPrimaryBgColorDisabled]: { varName: '--sp-btn-primary-bg-color-disabled', description: 'Background color (when disabled)' },

  // Secondary button
  [cssVariables.spBtnSecondaryColor]: { varName: '--sp-btn-secondary-color', description: 'Text color' },
  [cssVariables.spBtnSecondaryBorderColor]: { varName: '--sp-btn-secondary-border-color', description: 'Border color' },
  [cssVariables.spBtnSecondaryBgColor]: { varName: '--sp-btn-secondary-bg-color', description: 'Background color' },
  [cssVariables.spBtnSecondaryBgColorHover]: { varName: '--sp-btn-secondary-bg-color-hover', description: 'Background color (on hover)' },
  [cssVariables.spBtnSecondaryColorHover]: { varName: '--sp-btn-secondary-color-hover', description: 'Text color (on hover)' },
  [cssVariables.spBtnSecondaryColorDisabled]: { varName: '--sp-btn-secondary-color-disabled', description: 'Text color (when disabled)' },
  [cssVariables.spBtnSecondaryBgColorDisabled]: { varName: '--sp-btn-secondary-bg-color-disabled', description: 'Background color (when disabled)' },
  [cssVariables.spBtnSecondaryBorderColorDisabled]: { varName: '--sp-btn-secondary-border-color-disabled', description: 'Border color (when disabled)' },

  // Secondary button with icon
  [cssVariables.spBtnSecondaryWithIconColor]: { varName: '--sp-btn-secondary-with-icon-color', description: 'Text color' },
  [cssVariables.spBtnSecondaryWithIconBorderColor]: { varName: '--sp-btn-secondary-with-icon-border-color', description: 'Border color' },
  [cssVariables.spBtnSecondaryWithIconBgColor]: { varName: '--sp-btn-secondary-with-icon-bg-color', description: 'Background color' },
  [cssVariables.spBtnSecondaryWithIconBgColorHover]: { varName: '--sp-btn-secondary-with-icon-bg-color-hover', description: 'Background color (on hover)' },
  [cssVariables.spBtnSecondaryWithIconColorHover]: { varName: '--sp-btn-secondary-with-icon-color-hover', description: 'Text color (on hover)' },
  [cssVariables.spBtnSecondaryWithIconBorderColorHover]: { varName: '--sp-btn-secondary-with-icon-border-color-hover', description: 'Border color (on hover)' },
  [cssVariables.spBtnSecondaryWithIconColorDisabled]: { varName: '--sp-btn-secondary-with-icon-color-disabled', description: 'Text color (when disabled)' },
  [cssVariables.spBtnSecondaryWithIconBgColorDisabled]: { varName: '--sp-btn-secondary-with-icon-bg-color-disabled', description: 'Background color (when disabled)' },
  [cssVariables.spBtnSecondaryWithIconBorderColorDisabled]: { varName: '--sp-btn-secondary-with-icon-border-color-disabled', description: 'Border color (when disabled)' },

  // Navigation
  [cssVariables.spNavbarLink]: { varName: '--sp-navbar-link', description: 'Navigation link color' },
  [cssVariables.spNavbarLinkHover]: { varName: '--sp-navbar-link-hover', description: 'Navigation link color (on hover)' },
  [cssVariables.spNavbarLinkBottom]: { varName: '--sp-navbar-link-bottom', description: 'Navigation link border' }
};
