// use vue-template-compiler build in development for handling <component/> tags in django templates
import { createApp } from 'vue';
import { installGalleryLib, setupTranslations, useSharedRouterSettings } from '@yuzulabs/lib-gallery';
import { initializeSentry } from '@yuzulabs/lib-infra';
import App from './App';
import router from './router';
import store from './store';
// Import general style
import './style.scss';


const storageKey = 'ohsu_language';
store.commit('general/setLanguageSelectionStorageKey', storageKey);

const app = createApp(App)
  .use(store)
  .use(installGalleryLib) // Initialize gallery lib + font awesome icons and components
  .use(router)
  .use(setupTranslations(storageKey))
  .use(initializeSentry({ router }));
app.mount('#vue-gallery-app');

// Record page views, and set a route guard in place to wait for gallery settings to be loaded
useSharedRouterSettings(router, store);
