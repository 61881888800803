<!-- A list of "featured" studies (to be shown on the homepage) -->
<script setup>
import { computed, ref } from 'vue';
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import StudyCardSmall from '../studies/card/StudyCardSmall.vue';

const props = defineProps({
  studies: {
    type: Array,
    required: true
  }
});
const deviceSize = useResizeListener().currentDeviceSize;
const numPerPage = computed(() => {
  if (deviceSize.value >= DeviceSize.md) {
    return 3;
  }
  return deviceSize.value >= DeviceSize.sm ? 2 : 1;
});
const numPages = computed(() => Math.ceil(props.studies.length / numPerPage.value));
const currentPage = ref(1);
const visibleCards = computed(() => {
  const start = (currentPage.value - 1) * numPerPage.value;
  let end = start + numPerPage.value;
  end = end > props.studies.length ? props.studies.length : end;
  return props.studies.slice(start, end);
});
// What direction is the carousel going to: left or right? This helps the animations
const goingRight = 'right';
const goingLeft = 'left';
const activeDirection = ref(goingRight);
function nextPage() {
  if (currentPage.value < numPages.value) {
    activeDirection.value = goingRight;
    currentPage.value++;
  }
}

function previousPage() {
  if (currentPage.value > 1) {
    activeDirection.value = goingLeft;
    currentPage.value--;
  }
}

function goToPage(newPage) {
  if (newPage === currentPage.value) {
    return;
  }

  activeDirection.value = newPage > currentPage.value ? goingRight : goingLeft;
  currentPage.value = newPage;
}

</script>

<template>
  <div v-if="visibleCards.length" id="loc-featured-studies-wrapper" class="d-flex flex-row w-100">
    <!-- Previous page -->
    <div v-if="numPages > 1" class="mx-2 d-flex align-items-center">
      <font-awesome-icon :class="{ disabled: currentPage <= 1 }"
                         class="page-btn"
                         icon="angle-left"
                         @click="previousPage" />
    </div>
    <div>
      <!-- Progress indicators -->
      <div v-if="numPages > 1" class="d-flex justify-content-end w-100 pe-2 pb-2">
        <div v-for="i in numPages"
             :key="i"
             :class="{ active: i === currentPage }"
             class="progress-btn ms-1"
             @click="goToPage(i)">
        </div>
      </div>
      <!-- Study cards -->
      <TransitionGroup name="study-cards"
                       tag="div"
                       :class="[activeDirection === goingRight ? 'going-right' : 'going-left']"
                       class="d-flex align-items-stretch">
        <div v-for="studyCard in visibleCards" :key="studyCard.id" class="min-width m-2">
          <StudyCardSmall :featured-mode="true" :study="studyCard" />
        </div>
      </TransitionGroup>
    </div>
    <!-- Next page -->
    <div v-if="numPages > 1" class="mx-2 d-flex align-items-center">
      <font-awesome-icon :class="{ disabled: currentPage >= numPages }"
                         class="page-btn"
                         icon="angle-right"
                         @click="nextPage" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.study-cards-enter-active{
  transition: all 1.5s ease;
}

.study-cards-enter-from {
  opacity: 0;
}

.going-right {
  .study-cards-enter-from {
    transform: translateX(50px);
  }
}

.going-left {
  .study-cards-enter-from {
    transform: translateX(-50px);
  }
}

.study-cards-enter-to {
  opacity: 1;
}

.study-cards-leave-active {
  display: none; // Immediately destroy components without transition so it doesn't collide with newly added items
}

.min-width {
  max-width: 370px;
  width: 100%;
}

.page-btn {
  font-size: 38px;
  color: var(--grey-300);
  &:not(.disabled):hover {
    cursor: pointer;
    color: var(--grey-400);
  }
  &.disabled {
    color: var(--grey-200);
  }
}

.progress-btn {
  cursor: pointer;
  width: 1.25rem;
  height: 0.325rem;
  background-color: var(--grey-300);
  &.active {
    background-color: var(--accent-primary);
  }

  &:first-child {
    border-radius: 1rem 0 0 1rem;
  }

  &:last-child {
    border-radius: 0 1rem 1rem 0;
  }
}
</style>
