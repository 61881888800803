<template>
  <font-awesome-icon v-if="scrollToTopIsVisible"
                     class="scroll-top"
                     icon="chevron-square-up"
                     @click="scrollToTop()" />
</template>

<script>
export default {
  data() {
    return {
      scrollToTopIsVisible: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // Unfortunately @scroll doesn't work on a complete page, so we have to register event listener the old way
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleScroll() {
      this.scrollToTopIsVisible = window.scrollY > 200;
    }
  }
};
</script>
<style lang="scss" scoped>
.scroll-top {
  cursor: pointer;
  font-size: 30px;
  position: fixed;
  color: var(--accent-primary);
  right: 20px;
  bottom: 20px;
  z-index: 1015;

  &:hover {
    color: var(--accent-secondary);
  }
}
</style>
