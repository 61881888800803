import { constants } from '../constants';

/**
 * Overwrite css variable values with config data coming from the backend
 * @param themeConfig
 */
export function overruleThemeCssVariables(themeConfig) {
  Object.entries(themeConfig || {}).forEach(([key, colorValue]) => {
    // Lookup to which actual css variable this config property corresponds to
    const cssVariableName = constants.cssVariableMappings[key];
    if (cssVariableName) {
      document.documentElement.style.setProperty(cssVariableName.varName, colorValue);
    }
  });
}
