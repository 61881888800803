<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import { constants } from '../../constants';

const store = useStore();
const selectionStorageKey = store.state.general.languageSelectionStorageKey;
const deviceSize = useResizeListener().currentDeviceSize;

// Check if a language was previously stored in local storage and show that
const activeLanguage = ref(constants.allLanguages[localStorage.getItem(selectionStorageKey)] || constants.allLanguages.en);

// Grab languages available for this gallery from gallery config, stored in state
const languages = computed(() => store.state.general.languages);

// Show languages in dropdown except the current active one
const availableLanguages = computed(() => languages.value
  .filter(key => activeLanguage.value.key !== key)
  .map(key => constants.allLanguages[key]));

const selectLanguage = language => {
  if (language.key === activeLanguage.value.key) {
    return;
  }
  localStorage.setItem(selectionStorageKey, language.key);
  window.location.reload(); // TODO: do language switching automatically without reloading https://kazupon.github.io/vue-i18n/guide/locale.html
};
</script>

<template>
  <div class="dropdown">
    <button :class="[deviceSize >= DeviceSize.md ? 'normal-size' : 'compact-size']"
            class="btn dropdown-toggle select-lang grey-500 small-12 active-lang"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
      {{ activeLanguage.name }}
    </button>
    <div class="dropdown-menu w-100 select-lang p-0 m-0">
      <div v-for="lang in availableLanguages"
           :key="lang.key"
           :class="[deviceSize >= DeviceSize.md ? 'normal-size' : 'compact-size']"
           class="dropdown-item d-flex align-items-center justify-content-center"
           @click="selectLanguage(lang)">
        <div class="grey-500 small-12">
          {{ lang.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$height-normal: 50px;
$height-compact: 40px;

.active-lang {
  &.normal-size {
    height: $height-normal;
  }
  &.compact-size {
    height: $height-compact;
  }

  font-weight: 400;
  &:after {
    content: none;
  }
}
.dropdown-toggle.select-lang {
  border: 1px solid var(--grey-200);
  border-radius: 6px;
  &:hover {
    color: var(--grey-500);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.dropdown-menu.select-lang {
  z-index: 1030;
  min-width: initial;
  border-color: var(--grey-200);
  .dropdown-item {
    &.normal-size {
      height: $height-normal;
    }
    &.compact-size {
      height: $height-compact;
    }
    &:hover {
      border-radius: 3px;
      cursor: pointer;
      color: white;
      background-color: var(--accent-secondary);
      div {
        color: white;
      }
    }
  }
}
</style>
