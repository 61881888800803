import { cssVariableMappings } from '@yuzulabs/lib-general';

export const constants = {
  // Add more languages here if a gallery requires it
  allLanguages: {
    en: { key: 'en', name: 'English' },
    es: { key: 'es', name: 'Español' }
  },
  staticPageTypes: {
    about: 'about',
    contact: 'contact',
    privacyPolicy: 'privacy_policy',
    terms: 'terms'
  },
  routeNames: {
    home: 'home',
    studies: 'studies',
    about: 'about',
    privacyPolicy: 'privacy-policy',
    terms: 'terms',
    contact: 'contact'
  },
  footerContentTypes: {
    externalRef: 'external-ref' // Other type is 'platform-ref' but that is not needed here
  },
  cssVariableMappings: {
    ...cssVariableMappings
  }
};
