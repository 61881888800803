/**
 * Allows consuming packages to use one shared instance of Axios, with all headers and interceptors set correctly
 */
import axios from 'axios';
import qs from 'qs';

const enableCsrf = !window.skipCsrf;
const csrfHeaderKey = 'X-CSRFToken';
function setCsrfHeader(axiosInstance, csrfToken) {
  axiosInstance.defaults.headers.common[csrfHeaderKey] = csrfToken;
}
async function getCsrfTokenFromApi(apiBaseUrl) {
  try {
    const response = await axios.get(`${apiBaseUrl}/get-csrf`);
    const token = response.data.csrf_token;
    if (!token) {
      throw new Error('Empty token returned from API');
    }
    return token;
  } catch (error) {
    console.error('Error fetching CSRF token through API:', error);
    throw error;
  }
}

const axiosInstance = axios.create({});
if (enableCsrf) {
  axiosInstance.defaults.xsrfHeaderName = csrfHeaderKey;
}

axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
const apiBaseUrl = `${window.location.origin}/api/v1`;

if (enableCsrf) {
  // Get csrf token from hidden input field in django template
  let csrfToken = document.getElementsByName('csrfmiddlewaretoken')[0]?.value;

  // Fallback: get token from backend
  if (!csrfToken) {
    csrfToken = await getCsrfTokenFromApi(apiBaseUrl);
  }
  setCsrfHeader(axiosInstance, csrfToken);
}

window.axios = axiosInstance;
window.qs = qs;

const pendingRequests = new Map();
let csrfRetryCounter = 0;

axiosInstance.interceptors.request.use(async config => {
  const requestId = config.abortController?.key;
  if (requestId) {
    const controller = config.abortController;
    if (pendingRequests.has(requestId)) {
      pendingRequests.get(requestId)?.abort('Duplicate request canceled');
      pendingRequests.delete(requestId);
    }
    pendingRequests.set(requestId, controller);
  }

  return config;
});

if (enableCsrf) {
  const clearPendingRequest = response => {
    const requestId = response?.config?.requestId;
    if (requestId) {
      pendingRequests.delete(requestId);
    }
  };

  axiosInstance.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    response => {
      csrfRetryCounter = 0;
      clearPendingRequest(response);
      return response;
    },
    // Any status code that falls outside the range of 2xx cause this function to trigger
    async error => {
      clearPendingRequest(error);

      // Do a hard-coded check for invalid CSRF responses from the backend
      // When this happens, get a fresh token from the backend and reply this request (only try this 5 times)
      const isConfirmedCsrfError = error.response?.data?.detail === 'CSRF Failed: CSRF token from the \'X-Csrftoken\' HTTP header incorrect.';

      // Sometimes we just get a 403, but without the csrf message, in that case we're not 100% sure it's a csrf issue
      // In that case, we only retry once
      const maxCsrfRetries = isConfirmedCsrfError ? 5 : 1;
      if (error.response?.status === 403 && csrfRetryCounter < maxCsrfRetries) {
        csrfRetryCounter++;

        // Get fresh token and set it as the default one for future requests
        const freshCsrfToken = await getCsrfTokenFromApi(apiBaseUrl);
        setCsrfHeader(axiosInstance, freshCsrfToken);

        // Replay the current failed request with the same config, but with updated csrf token
        const initialRequestConfig = error.response.config;
        initialRequestConfig.headers[csrfHeaderKey] = freshCsrfToken;
        return axiosInstance(initialRequestConfig);
      }
      csrfRetryCounter = 0;
      return Promise.reject(error);
    }
  );
}


export default axiosInstance;
