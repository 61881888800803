import { FooterModel } from '../models';
import { overruleThemeCssVariables } from '../utils';

let gallerySettingsLoadedResolveFunc = null;
export class GeneralStore {
  constructor(generalService) {
    // Default state generator
    const getDefaultState = () => ({
      apiEndpoint: '',
      subscriptionsEnabled: false,
      galleryCode: null,
      galleryUrlName: '',
      researcherLogin: null,
      galleryPhone: null,
      galleryTitle: null,
      galleryWindowTitle: null,
      galleryLogo: null,
      galleryLogoLink: null,
      showHomeCounts: true,
      footerData: new FooterModel(),
      lastRoute: null,
      googlePublicBrowserKey: null,
      routeRecordTimer: null,
      customData: {},
      languages: [],
      languageSelectionStorageKey: '',
      gallerySettingsLoaded: new Promise(resolve => {
        gallerySettingsLoadedResolveFunc = resolve;
      }) // This promise will resolve when gallery settings are loaded (needed for vue route guards)
    });

    this.state = getDefaultState(); // Initialize general state

    this.getters = {
      apiEndpoint: state => state.apiEndpoint,
      subscriptionsEnabled: state => state.subscriptionsEnabled,
      showHomeCounts: state => state.showHomeCounts,
      galleryPhone: state => state.galleryPhone,
      galleryCode: state => state.galleryCode,
      researcherLogin: state => state.researcherLogin,
      galleryLogo: state => state.galleryLogo || '',
      galleryLogoLink: state => state.galleryLogoLink || '',
      galleryTitle: state => state.galleryTitle || '',
      googlePublicBrowserKey: state => state.googlePublicBrowserKey,
      customData: state => state.customData,
      galleryWindowTitle: state => state.galleryWindowTitle,
      languageSwitcherVisible: state => state.languages.length > 1
    };

    this.mutations = {
      setGalleryConfigData: (state, configData) => {
        if (!configData) {
          return;
        }
        // Append '/' to current origin location if not present
        let currentLocation = window.location.origin;
        currentLocation = currentLocation.endsWith('/') ?
          currentLocation : `${currentLocation}/`;
        // Append gallery endpoint part, e.g. 'ucdavis'
        state.apiEndpoint = currentLocation + configData.galleryUrlName;
        state.subscriptionsEnabled = configData.subscriptionsEnabled;
        state.galleryCode = configData.galleryCode;
        state.galleryPhone = configData.galleryPhone;
        state.showHomeCounts = configData.showHomeCounts;
        state.footerData = new FooterModel(configData.footer, configData.staticPages);
        state.researcherLogin = configData.researcherLogin;
        state.googlePublicBrowserKey = configData.googlePublicBrowserKey;
        state.customData = configData.customData || {};
        state.languages = configData.languages || [];
      },
      resetLastRouteAndTimer: (state, { lastRoute, timer }) => {
        if (state.routeRecordTimer) {
          clearTimeout(state.routeRecordTimer);
        }
        state.lastRoute = lastRoute;
        state.routeRecordTimer = timer;
      },
      setLanguageSelectionStorageKey(state, key) {
        state.languageSelectionStorageKey = key;
      },
      setGallerySettings(state, payload) {
        state.galleryTitle = payload.title;
        state.galleryLogo = payload.logo;
        state.galleryLogoLink = payload.logoLink;
        state.galleryWindowTitle = payload.windowTitle;
      }
    };

    this.actions = {
      /**
             *  Record every page view, after a certain time
             */
      async recordPageView({ commit, state }, { toPathName, fromPathName }) {
        if (toPathName && toPathName !== fromPathName) {
          // Wait a little bit before recording page views
          // This way, quick page switches are not recorded, because they are not relevant
          // You have to stay at least 2500 ms on the page for this to be recorded
          commit('resetLastRouteAndTimer', {
            lastRoute: toPathName,
            timer: setTimeout(() => {
              // We intentionally not 'await' this promise
              // because setTimeOut can't handle async/await, but it doesn't matter here anyway
              generalService.recordPageView(state.lastRoute);
            }, 2500)
          });
        }
      },
      async initGallery({ commit }, galleryConfigData) {
        try {
          commit('setGalleryConfigData', galleryConfigData);
          const gallerySettings = await generalService.getGallerySettings(galleryConfigData.galleryUrlName);
          commit('setGallerySettings', gallerySettings);
          overruleThemeCssVariables(gallerySettings.cssVariables);
        } finally {
          gallerySettingsLoadedResolveFunc(true); // So route guards waiting for the gallerySettingsLoaded Promise can continue
        }
      }
    };
  }
}
