<template>
  <div :style="categoryLeftPadding" class="d-flex flex-row section-link" @click.stop="itemClicked(searchCategory)">
    <div class="align-self-center ms-0 me-2 accent-primary">
      <!-- Unfortunately, this could be either a Flaticon or a Fontawesome icon (class data comes from backend) -->
      <!-- When DOM is created, these get transformed to Fontawesome svg's where possible: dom.i2svg() -->
      <i v-if="isTopLevel" :class="searchCategory.icon" />
      <font-awesome-icon v-if="!isTopLevel" class="sub-category-icon grey-700" icon="angle-right" />
    </div>
    <div :class="[isTopLevel ? 'body-16' : 'body-14']" class="align-self-center grey-700">
      {{ searchCategory.label }}<template v-if="showCounts">
        ({{ searchCategory.studyCount }})
      </template>
    </div>
  </div>
  <BrowsableSectionItem v-for="child in searchCategory.children"
                        :key="child.id"
                        :level="level + 1"
                        :search-category="child"
                        :show-counts="showCounts"
                        @item-clicked="itemClicked($event, true)" />
</template>
<script>

import { AggregatedGscModel } from '../../models';

export default {
  props: {
    searchCategory: {
      type: AggregatedGscModel,
      required: true
    },
    level: {
      // How deep in the nested GallerySearchCategory tree are we? (starts at 0)
      type: Number,
      required: true
    },
    showCounts: {
      type: Boolean,
      default: true
    }
  },
  emits: ['itemClicked'],
  computed: {
    isTopLevel() {
      return this.level === 0;
    },
    categoryLeftPadding() {
      // For each level deeper, we shift to the left by x pixels, first level doesn't shift
      const margin = this.level * 20;
      return {
        'margin-left': `${margin}px`
      };
    }
  },
  methods: {
    itemClicked(data, alreadyMapped = false) {
      this.$emit('itemClicked', alreadyMapped ? data : data.mapToSearchFilter());
    }
  }
};
</script>
<style lang="scss" scoped>
.section-link {
  cursor: pointer;

  &:hover {
    color: grey;
  }

  i:before {
    margin-left: 0; // reset default flaticon 'before' margin
  }

  .sub-category-icon {
    font-size: 13px;
  }
}
</style>
